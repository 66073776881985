import { Block, Divider, Flexbox, Grid, Paragraph } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import PageTitle from '@src/components/PageTitle'
import PageText from '@src/components/PageText'
import Cube from '@src/components/Cube'

import Orbits from '@src/components/Orbits'

const S07Experience = (props: FlexboxTypes.Props) => {
  return (
    <Flexbox column {...props}>
      <PageTitle label="О команде" title="Экспертный опыт" />
      <Grid gap={['2.5rem', '1rem']} templateColumns={['1fr 1fr']} mt="2.5rem">
        <Block>
          <Cube
            scale={1.5}
            blur={5}
            hue={70}
            duration={20}
            style={{
              opacity: 0.6,
              position: 'absolute',
              left: '-12rem',
              top: '10rem',
            }}
          />
          <Paragraph weight={800} color="black" size={['4.5rem', '2.5rem']} mt="-1rem">
            20+
          </Paragraph>
          <Paragraph weight={700} color="gray900" size={['2.5rem', '1.5rem']}>
            лет
          </Paragraph>
          <Divider w="10rem" my="1rem" color="gray300" />
          <PageText size={['1.25rem', '0.875rem']}>
            Опыт партнеров <br />
            HoReCa
          </PageText>
        </Block>
        <Block>
          <Paragraph weight={800} color="black" size={['4.5rem', '2.5rem']} mt="-1rem">
            10+
          </Paragraph>
          <Paragraph weight={700} color="gray900" size={['2.5rem', '1.5rem']}>
            лет
          </Paragraph>
          <Divider w="10rem" my="1rem" color="gray300" />
          <PageText size={['1.25rem', '0.875rem']}>
            Опыт в построении сложных высоконагруженных IT проектах
          </PageText>
        </Block>
      </Grid>
      <Orbits left={['50rem', '25rem']} bottom="8rem" position="absolute" />
    </Flexbox>
  )
}

export default S07Experience
